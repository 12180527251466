<template>
    <div class="message-panel">
        <h3 class="g-title">发送消息</h3>
        <div class="send-msg-panel">
            <div class="msg-reply-panel">
                <div class="success-tips-panel">
                    <span class="icon"><img :src="$img.success_ico"></span>
                    <div class="success-txt">
                        <h4>报名成功</h4>
                        <p>消息已经发送成功</p>
                        <el-button type="primary">返回消息中心</el-button>
                    </div>
                </div>
            </div>
            <div class="contact-panel">
                <el-card
                    class="box-card"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>最近联系的人</span>
                    </div>
                    <div class="text item">
                        卖草鞋争天下<GK52582123... </div>
                            <div class="text item">
                                Join
                            </div>
                            <div class="text item">
                                云南工程建设投资公司
                            </div>
                            <div class="text item">
                                speed科技公司
                            </div>
                            <div class="text item">
                                昆明极客工程有限公司...
                            </div>
                </el-card>
                <el-card
                    class="box-card"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>我关注的人</span>
                    </div>
                    <div class="text item">
                        卖草鞋争天下<GK52582123... </div>
                            <div class="text item">
                                Join
                            </div>
                            <div class="text item">
                                云南工程建设投资公司
                            </div>
                            <div class="text item">
                                speed科技公司
                            </div>
                            <div class="text item">
                                昆明极客工程有限公司...
                            </div>
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "sendsuccess",
    components: {},
    data() {
        return {

        };
    },
    methods: {

    }
};
</script>
<style lang="less">
@import "../../../styles/message.less";
</style>


